window.addEventListener('load', () => {

  // js parallax
  let renderSyncEducation = false
  let scrollEvent = () => {
    if (!renderSyncEducation) {
        requestAnimationFrame(() => {
        renderSyncEducation = false

        let currentScrl = window.pageYOffset + window.innerHeight
        // let Room01  = document.getElementsByClassName('prlx-01')[0].getBoundingClientRect().top + pageYOffset
        // let Room01H  = document.getElementsByClassName('prlx-01')[0].getBoundingClientRect().height
        let Room02  = document.getElementsByClassName('prlx-02')[0].getBoundingClientRect().top + pageYOffset
        let Room02H  = document.getElementsByClassName('prlx-02')[0].getBoundingClientRect().height
        let Room03  = document.getElementsByClassName('prlx-03')[0].getBoundingClientRect().top + pageYOffset
        let Room03H  = document.getElementsByClassName('prlx-03')[0].getBoundingClientRect().height

        //scroll parallax
        // if (window.innerHeight >= window.pageYOffset) {
        //   // [15 - 25] : ほぼphotoを固定したような感じ  [ 30 - 50]
        //   let calcPosition = window.pageYOffset / 32
        //   document.getElementsByClassName('js_parallax')[0].style.top = (50 + calcPosition) + '%'
        // }

        // if (Room01 <= currentScrl && Room01 + Room01H >= window.pageYOffset) {
        //   let calcPosition01 = (window.innerHeight - document.getElementsByClassName('prlx-room01')[0].getBoundingClientRect().top) / 32 * 1
        //   document.getElementsByClassName('prlx-room01')[0].style.top = (50 + calcPosition01) + '%'
        // }
        if (Room02 <= currentScrl && Room02 + Room02H >= window.pageYOffset) {
          let calcPosition02 = (window.innerHeight - document.getElementsByClassName('prlx-room02')[0].getBoundingClientRect().top) / 60 * 1
          document.getElementsByClassName('prlx-room02')[0].style.top = (-20 + calcPosition02) + '%'
        }
        if (Room03 <= currentScrl && Room03 + Room03H >= window.pageYOffset) {
          let calcPosition03 = (window.innerHeight - document.getElementsByClassName('prlx-room03')[0].getBoundingClientRect().top) / 60 * 1
          document.getElementsByClassName('prlx-room03')[0].style.top = (-20 + calcPosition03) + '%'
        }
      }) //requestAnimationFrame
    } //if rendersyncEducation
  } //scrollEvent
  document.addEventListener('scroll', scrollEvent, {passive: true});
  // end js parallax
})
